import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';

import '../../generic-page.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const AshGuides: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>Guides</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_guides.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Ash Echoes Guides</h1>
          <h2>
            Best guides for Ash Echoes that will help you understand the game
            better
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="Work in progress" />
      <div className="banner countdown">
        <div className="cta">
          <h4>Our Ash Echoes Database is currently being created!</h4>
          <p>
            We're currently gathering all necessary information for the upcoming
            launch!
          </p>
        </div>
      </div>
      <SectionHeader title="Newbie Zone" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/ash-echoes/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../../images/ash/categories/category_intro.webp"
              alt="Introduction to the game"
            />
          }
        />
        <CategoryCard
          title="Reroll Guide"
          link="/ash-echoes/guides/reroll"
          image={
            <StaticImage
              src="../../../images/ash/categories/category_reroll.webp"
              alt="Reroll Guide"
            />
          }
          isWIP
        />
        <CategoryCard
          title="Beginner Guide"
          link="/ash-echoes/guides/beginner-guide"
          image={
            <StaticImage
              src="../../../images/ash/categories/category_beginner.webp"
              alt="Beginner Guide"
            />
          }
          isWIP
        />
      </Row>
    </DashboardLayout>
  );
};

export default AshGuides;

export const Head: React.FC = () => (
  <Seo
    title="Guides | Ash Echoes | Prydwen Institute"
    description="Best guides for Ash Echoes that will help you understand the game better."
    game="ash"
  />
);
